<template>
  <div>
    <header>
      <van-nav-bar
        title="推荐路线"
        left-text="返回"
        left-arrow
        placeholder
        @click-left="onClickLeft"
      />
    </header>
    <h2>10条自驾游线路</h2>
    <div class="main">
      <div
        class="li"
        v-for="(item, index) in list"
        :key="index"
        @click="push(index, item)"
      >
        <span class="id">{{ index + 1 }}</span>
        <p>
          <span class="text" v-for="(data, index) in item" :key="index">
            {{ item[index].name
            }}<svg
              t="1639213585161"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1345"
              width="200"
              height="200"
            >
              <path
                d="M791.4 252.1l214 214c25 25 25 65.5 0 90.5l-215 215c-1.6 1.6-4.1 1.6-5.7 0L745.1 732c-1.6-1.6-1.6-4.1 0-5.7l175.7-175.7c2.5-2.5 0.7-6.8-2.8-6.8H4c-2.2 0-4-1.8-4-4v-56c0-2.2 1.8-4 4-4H919.1c3.6 0 5.3-4.3 2.8-6.8L746.2 297.3c-1.6-1.6-1.6-4.1 0-5.7l39.6-39.6c1.5-1.5 4.1-1.5 5.6 0.1z"
                p-id="1346"
              ></path>
            </svg>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    let abbreviation = JSON.parse(
      sessionStorage.getItem("abbreviation")
    ).travelAbbreviation;
    this.$axios
      .post("/api/map/getTrRecommendedRoute", {
        abbreviation: abbreviation,
      })
      .then((res) => {
        if (res.code == 0) {
          this.list = res.data;
        } else {
          this.$toast.fail("请求失败，请刷新页面");
        }
      });
  },
  methods: {
    ...mapMutations(["setPathData"]),
    onClickLeft() {
      this.$router.push("/Home");
    },
    push(i, val) {
      this.setPathData(val); //将数据存储
      this.$router.push({
        path: "/",
        query: {
          pathData: { ...val },
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
h2 {
  text-align: center;
}
.main {
  .li {
    margin: 0.2rem 0;
    background: #ddd;
    line-height: 0.45rem;
    .id {
      display: inline-block;
      margin: 0 0.2rem;
      margin-right: 0;
      width: 0.45rem;
      height: 0.45rem;
      border-radius: 50%;
      color: #fff;
      background: #0a9df1;
      font-size: 0.24rem;
      text-align: center;
    }
    .text {
      display: inline-block;
      line-height: 0.5rem;
    }
    .text:last-child .icon {
      display: none;
    }
    p {
      display: inline-block;
      margin: 0.1rem 0.2rem;
      font-size: 0.28rem;
    }
    .icon {
      position: relative;
      top: 0.04rem;
      margin: 0 0.1rem;
      width: 0.28rem;
      height: 100%;
      font-size: 0.28rem;
      line-height: 0.5rem;
    }
  }
}
</style>